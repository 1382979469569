import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create API slice
export const checkoutByMadfuApi = createApi({
	reducerPath: "checkoutByMadfuApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/",
	}),
	endpoints: (builder) => ({
		// Checkout with madfu payment method
		loginWithMadfu: builder.mutation({
			query: ({ body }) => ({
				url: `madfu/login`,
				method: "POST",
				body: body,
				headers: {
					Authorization: `Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI5OWI4YzMwZS04YjQ0LTRhZmYtYTZjNi1kN2RhNGJlMzhhYzUiLCJqdGkiOiIwNzdhOWQ3MTRkZmEzNGIwN2I4ZjI4NDRhZjhhNjQ0YjFmNjZkZjAxNjI1ZmQ3NTIxZDQ2ZDA2ZDVjNDYwMzg5YjBhYjRiMmFkY2VhMjE0MyIsImlhdCI6MTcxNzkyOTAyNi43NzcxODkwMTYzNDIxNjMwODU5Mzc1LCJuYmYiOjE3MTc5MjkwMjYuNzc3MTg5OTcwMDE2NDc5NDkyMTg3NSwiZXhwIjoxNzQ5NDY1MDI2Ljc3NDgwMjkyMzIwMjUxNDY0ODQzNzUsInN1YiI6IjEiLCJzY29wZXMiOltdfQ.aVJIX1pZrYUVJECLeK7LJJ7oA0ZaiBPbg67XqtxXqKXWG0pEAOwlA80njnm2B_dFUtPYUVSddnrfsD_IGIQ8peoZisIEEcCVZf_aY4kablGX8C5iNjbB6KUxN7IStootdZ_F6rFBGm4GV0dluP_bi1ahrNs57Mudc-NR0WHaO0iMmRfTZdE4ul18l-DxelQQzJhyenp1UE0YoMEsjcT0YP7OiNDXKp8OJo-JSj1xppPUel98Qh9pMgC2TT1MxII3VOk6gxS9zEh5vmbnqYDuTqljH_1sewgp3amjjDw_gxGcUau4YpvbQb3AA6HjggUU-HEyca_3p-NuXHVc4iyzNlajllETU6dBu1WZJqDXZ_euIKikMpxFMbnS6P4YBQg_6XouaGmdcVj-1TtzP39EKFJeAbLHnUPDHTPtvE7TXxghkYuJl7MJ3bBkW5QCjmcNbSS2oGUl_9LmjfkE24PVZlp9rVw22ceKSrJwgmFwvGRVCf_AdEgqW20ciAnKiBf9O_JtlzI9RpgYAUVntai2dFmQ-RCegtjDQQqW1zx38dRNWPSi9dnP6vmiDe8rxB9w7CjugG5nn9GsJvdZtFgBCRPZgAkD8sTcBQcJSU0EUpne67y_kzjxwQ1AUN1X8LM2gq8o_BQcZv_YnKil6LV1hkZ0JVOexrtmglVXYuGHRts`,
				},
			}),
		}),

		// Handle create order with madfu payment method
		createOrderWithMadfu: builder.mutation({
			query: ({ body }) => ({
				url: `madfu/create-order`,
				method: "POST",
				body: body,
			}),
		}),
	}),
});

// Export endpoints and hooks
export const { useLoginWithMadfuMutation, useCreateOrderWithMadfuMutation } =
	checkoutByMadfuApi;
