import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Create API slice
export const souqAtlbhaProductsApi = createApi({
	reducerPath: "souqAtlbhaProductsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://backend.atlbha.sa/api/",
	}),

	tagTypes: ["Posts"],

	endpoints: (builder) => ({
		// get Home Page Data endpoint..
		getAllProducts: builder.query({
			query: ({ pageNumber, currentPage }) =>
				`home/products?page=${currentPage}&number=${pageNumber}`,

			// Pick out data and prevent nested properties in a hook or selector
			transformResponse: (response, meta, arg) => response.data,
		}),
	}),
});

// Export endpoints and hooks
export const { useGetAllProductsQuery } = souqAtlbhaProductsApi;
